<template>
  <div class="page1">
    <div @keydown.enter.prevent="submitData">
      <el-form
        label-position="right"
        label-width="120px"
        :model="formInline"
        :rules="rules"
        :ref="'form'"
      >
        <div class="content bgFFF">
          <h2 class="title">道路信息</h2>
          <div class="flex-box">
            <el-form-item :label="$t('searchModule.Road_hierarchy')" prop="slaveRelation">
              <el-select
                @change="handleSlaveChnage"
                v-model.trim="formInline.slaveRelation"
                :disabled="this.isEdit"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in slaveRelation"
                  :key="item.code"
                  :label="item.desc"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="formInline.slaveRelation == 2" label="项目批次" prop="projectBatch">
              <el-select v-model.trim="formInline.projectBatch" filterable placeholder="请选择">
                <el-option
                  v-for="(item, index) in projectBatch"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="关联道路"
              :prop="formInline.slaveRelation == 2 ? 'parentId' : ''"
              v-show="formInline.slaveRelation == 2"
            >
              <el-select
                v-model.trim="formInline.parentId"
                filterable
                placeholder="请选择"
                @change="slaveRelationChange"
              >
                <el-option
                  :label="value.parkName"
                  :value="value.parkId"
                  :key="value.parkId"
                  v-for="value in parkParent"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="slaveRelationDesc + '名称'"
              prop="parkName"
              :rules="[
                {
                  required: true,
                  message: `请输入${slaveRelationDesc}名称`,
                },
              ]"
            >
              <el-input
                :maxlength="20"
                v-model.trim="formInline.parkName"
                :placeholder="'请输入' + slaveRelationDesc + '名称'"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="slaveRelationDesc + '编码'"
              prop="parkAlias"
              :rules="[
                {
                  required: true,
                  message: `请输入${slaveRelationDesc}编码`,
                },
              ]"
            >
              <el-input
                :maxlength="20"
                v-model.trim="formInline.parkAlias"
                :placeholder="'请输入' + slaveRelationDesc + '编码'"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="slaveRelationDesc + '物理类型'"
              prop="parkType"
              :rules="[
                {
                  required: true,
                  message: `请选择${slaveRelationDesc}物理类型`,
                },
              ]"
            >
              <el-select v-model.trim="formInline.parkType" filterable placeholder="请选择">
                <el-option
                  :label="value.desc"
                  v-show="value.code != 3"
                  :value="value.code"
                  :key="value.code"
                  v-for="value in parkType"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="slaveRelationDesc + '设备类型'"
              prop="parkEquipmentType"
              :rules="[
                {
                  required: true,
                  message: `请选择${slaveRelationDesc}设备类型`,
                },
              ]"
            >
              <el-select
                v-model="formInline.parkEquipmentType"
                :placeholder="'请选择' + slaveRelationDesc + '设备类型'"
              >
                <el-option
                  :label="v.desc"
                  :value="v.code"
                  :key="v.code"
                  v-for="v in parkEquipmentType"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.region')" prop="areaId">
              <el-button
                :title="areaName ? areaName : '请选择'"
                :disabled="formInline.slaveRelation == 2"
                @click="areaIdDialog"
                class="dialog_button"
                >{{ areaName ? areaName : "请选择" }}
              </el-button>
            </el-form-item>
            <el-form-item label="经纬度" class="lng-box" prop="lnglat">
              <el-input
                :maxlength="20"
                v-model.trim="formInline.lnglat"
                placeholder="在地图上选择坐标点"
              ></el-input>
              <el-button
                type="primary"
                class="map1"
                @click="openMap"
                >地图选点
              </el-button>
            </el-form-item>
            <el-form-item :label="$t('searchModule.address')" prop="parkAddress">
              <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 1 }"
                :maxlength="20"
                v-model.trim="formInline.parkAddress"
                placeholder="输入详细地址"
                class="textarea-input"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Access_method')" prop="parkAccessType">
              <el-select
                v-model="formInline.parkAccessType"
                placeholder="请选择接入方式"
                :disabled="formInline.slaveRelation == 2"
              >
                <el-option
                  :label="v.desc"
                  :value="v.code"
                  :key="v.code"
                  v-for="v in accessList"
                ></el-option>
              </el-select>
            </el-form-item>
            <template
              v-if="
                !isEdit || formInline.slaveRelation == 2
                  ? (formInline.slaveRelation == 0 || formInline.slaveRelation == 2) &&
                    formInline.parkType == 4
                  : true
              "
            >
              <el-form-item label="泊位总数" prop="amount">
                <el-input
                  :maxlength="10"
                  v-model.number="formInline.amount"
                  :disabled="
                    formInline.slaveRelation == 1 ||
                    (formInline.slaveRelation == 0 && formInline.parkType != 4)
                      ? true
                      : false
                  "
                  placeholder="请输入泊位总数"
                ></el-input>
                <p
                  v-show="
                    formInline.slaveRelation == 1 ||
                    (formInline.slaveRelation == 0 && formInline.parkType != 4)
                      ? true
                      : false
                  "
                  class="tips-box"
                >
                  此处同步展示配置，是上线泊位之和
                </p>
              </el-form-item>
              <el-form-item label="是否支持充电" prop="chargeable">
                <el-radio
                  v-model="formInline.chargeable"
                  :label="0"
                  :disabled="
                    formInline.slaveRelation == 1 ||
                    (formInline.slaveRelation == 0 && formInline.parkType != 4)
                      ? true
                      : false
                  "
                  >否</el-radio
                >
                <el-radio
                  v-model="formInline.chargeable"
                  :label="1"
                  :disabled="
                    formInline.slaveRelation == 1 ||
                    (formInline.slaveRelation == 0 && formInline.parkType != 4)
                      ? true
                      : false
                  "
                  >是</el-radio
                >
                <p
                  v-show="
                    formInline.slaveRelation == 1 ||
                    (formInline.slaveRelation == 0 && formInline.parkType != 4)
                      ? true
                      : false
                  "
                  class="tips-box"
                >
                  以泊位是否支持充电为准
                </p>
              </el-form-item>
            </template>
            <div class="uploadPic">
              <div class="park-title">{{ slaveRelationDesc }}图片</div>
              <el-upload
                ref="parkUpload"
                class="upload-img"
                :class="{ hide: hideUpload }"
                action=""
                :headers="uploadHeader"
                :data="uploadData"
                name="image"
                accept="image/png, image/jpeg, image/jpg"
                :file-list="parkImgList"
                list-type="picture-card"
                :auto-upload="false"
                :limit="limitCount"
                :on-change="handleImgChange"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
              >
                <div class="park-icon-img">
                  <div class="park-icon-upload">+</div>
                  <div class="park-icon-title">点击或拖拽文件到此处上传。</div>
                  <div class="park-icon-box">
                    可上传1-2张图片，为了确保图片正常使用，仅支持3M以内jpg、png、jpeg格式图片上传，最佳效果宽高比4：3。
                  </div>
                </div>
              </el-upload>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="title-box" @click="isOperate = !isOperate">
            <i :class="isOperate ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
            <span>道路运营信息</span>
          </div>
          <div v-show="isOperate" class="flex-box mt20">
            <el-form-item :label="$t('searchModule.Merchant_affiliation')" prop="operationId">
              <el-select
                :disabled="formInline.slaveRelation == 2 || isEdit"
                v-model.trim="formInline.operationId"
                filterable
                size="15"
                @change="changeSelectOperationId($event)"
              >
                <el-option label="全部" value=""></el-option>
                <el-option
                  :label="value.operationName"
                  :value="value.operationId"
                  :key="value.operationId"
                  v-for="value in tenantList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="是否商业车场" prop="businessType">
              <el-select
                v-model="formInline.businessType"
                :disabled="formInline.slaveRelation == 2"
                placeholder="请选择是否商业车场"
              >
                <el-option
                  :label="v.desc"
                  :value="v.code"
                  :key="v.code"
                  v-for="v in businessType"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="合作模式" prop="cooperationModel">
              <el-select
                v-model="formInline.cooperationModel"
                :disabled="formInline.slaveRelation == 2"
                placeholder="请选择合作模式"
              >
                <el-option
                  :label="v.desc"
                  :value="v.code"
                  :key="v.code"
                  v-for="v in cooperationModel"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Payment_method')" prop="payMode">
              <el-select
                v-model="formInline.payMode"
                :disabled="formInline.slaveRelation == 2"
                placeholder="请选择支付方式"
              >
                <el-option
                  :label="v.desc"
                  :value="v.code"
                  :key="v.code"
                  v-for="v in payMode"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="扩展支付场景" prop="innerPayableOld">
              <el-select
                v-model="formInline.innerPayableOld"
                placeholder="请选择"
                multiple
                @change="changeInnerPayable"
              >
                <el-option label="无" :value="2"></el-option>
                <el-option label="场中支付" :value="1"></el-option>
                <el-option label="自主停车" :value="3"></el-option>
              </el-select>
              <div class="isMark" v-if="isShowMark">
                系统监测{{ nameShow }}商户未配置自主停车，请移步<span
                  style="color: blue"
                  @click="goSet"
                  >自主停车配置</span
                >进行配置后，再来选择扩展支付场景。
              </div>
            </el-form-item>
            <el-form-item
              v-if="formInline.innerPayableOld.length == 2 || getInnerPayable(1) == 1"
              label="免费驶离时长"
              prop="innerPayTime"
            >
              <el-input
                v-model.trim="formInline.innerPayTime"
                :disabled="formInline.slaveRelation == 2"
                placeholder="输入免费驶离时长"
                class="custom-input"
              ></el-input
              >分钟
              <p v-if="isgray" class="tips-box">
                车主场中支付后免费驶离的时长，取值范围[0，15]
              </p>
            </el-form-item>
            <el-form-item
              label="自主停车"
              v-if="getInnerPayable(3) == 3 && !isShowMark"
              prop="selfParkingId"
            >
              <el-select
                v-model="formInline.selfParkingId"
                :disabled="formInline.slaveRelation == 2"
                placeholder="请选择"
              >
                <el-option label="请选择" value="0"></el-option>
                <el-option
                  :label="v.configName"
                  :value="v.selfParkingId"
                  :key="v.selfParkingId"
                  v-for="v in getByOperaList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Commercial_status')" prop="commercialStatus">
              <el-select v-model="formInline.commercialStatus" placeholder="请选择">
                <el-option
                  v-for="v in commercialStatus"
                  v-show="v.code != 5"
                  :label="v.desc"
                  :value="v.code"
                  :key="v.code"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="道路状态" prop="parkState">
              <el-select v-model="formInline.parkState" placeholder="请选择">
                <el-option
                  :label="v.desc"
                  :value="v.code"
                  :key="v.code"
                  v-for="v in parkState"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.charge_type')" prop="scopeId">
              <el-select
                v-model="formInline.scopeId"
                :disabled="formInline.slaveRelation == 2"
                placeholder="请选择计费类型"
              >
                <el-option
                  :label="v.desc"
                  :value="v.code + ''"
                  :key="v.code"
                  v-for="v in parkScope"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="showPreCharge" label="预付款" prop="preCharge">
              <el-select v-model="formInline.preCharge" placeholder="请选择">
                <el-option label="支持" :value="1"></el-option>
                <el-option label="不支持" :value="0"></el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="content">
          <div class="title-box" @click="isRelevant = !isRelevant">
            <i :class="isRelevant ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
            <span>{{ slaveRelationDesc }}相关信息</span>
          </div>
          <div v-show="isRelevant" class="mt20 relevant-box">
            <el-form-item class="hotArea" label="所属热门商圈">
              <!-- :disabled="formInline.slaveRelation == 2" -->
              <el-transfer
                filterable
                :filter-method="filterMethod"
                filter-placeholder="请输入商圈名称"
                v-model="formInline.hotValue"
                :props="{
                  key: 'businessCenterId',
                  label: 'businessCenterName',
                }"
                :titles="['未选择', '已选择']"
                :data="hotData"
              >
              </el-transfer>
            </el-form-item>
            <div v-if="formInline.slaveRelation == 0 || formInline.slaveRelation == 1">
              <el-form-item label="泊位分布" prop="berthDeploy">
                <el-select v-model="formInline.berthDeploy" placeholder="请选择">
                  <el-option
                    :label="v.label"
                    :value="v.value"
                    :key="v.value"
                    v-for="v in berthDeployList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所在道路长度" prop="roadLength" class="length-box">
                <el-input
                  v-model.number="formInline.roadLength"
                  :maxlength="5"
                  placeholder="请输入所在道路长度"
                  oninput="value=value.replace(/[^\d]/g,'')"
                ></el-input
                ><span> 米 </span>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <div class="form-footer">
      <el-button type="primary" @click="submitData">提交</el-button>
      <el-button type="" @click="$router.go(-1)">取消</el-button>
    </div>
    <map-con ref="mapConpent" :city="city" @mapCancel="mapCancel" @mapSure="mapSure"></map-con>
    <tree-con
      ref="treeConpent"
      :defaultProps="defaultProps"
      :treeData="treeData"
      @treeSure="treeSure"
      @treeCandle="treeCandle"
    ></tree-con>
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public.js";
import mapCon from "@/components/map";
import treeCon from "./tree";

export default {
  name: "addNoClosePark",
  data() {
    let checkBerthNum = (rule, value, callback) => {
      if (value != parseInt(value)) {
        return callback(new Error("泊位数量必须为整数"));
      }
      if (value < 0) {
        this.flag = false;
        callback(new Error("泊位数量不能小于0"));
      } else {
        this.flag = true;
        callback();
      }
    };
    let checkinnerPayTime = (rule, value, callback) => {
      var pattern = /^[0-9][0-9]*$/;
      if (!pattern.test(value)) {
        callback(new Error("请输入正确的数字"));
        this.isgray = false;
      } else if (!value && value !== 0) {
        callback(new Error("请输入免费驶离时长"));
        this.isgray = false;
      } else if (Number(value) < 0 || Number(value) > 15) {
        callback(new Error("免费驶离时长范围为：0 ~ 15"));
        this.isgray = false;
      } else {
        callback();
        this.isgray = true;
      }
    };
    let checkOperationId = (rule, value, callback) => {
      callback();
    };
    return {
      showPreCharge: false,
      slaveRelationDesc: "道路", // 区分道路、路段描述文案
      isgray: true,
      saveLoading: "",
      isOperate: false,
      isRelevant: false,
      limitCount: 2,
      hideUpload: false,
      fileList: [],
      uploadImg: null,
      fileChang: false,
      parkState: [],
      parkScope: [],
      key1: "",
      city: "",
      parkName: "",
      dis: false,
      lnglat: "",
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      uploadHeader: {
        "Session-Id": sessionStorage.token,
      },
      uploadData: {
        parkId: "",
      },
      workStateAll: [],
      treeData: [],
      parkType: [],
      parkList: "",
      parkParent: [],
      butreeData: [],
      areaName: "",
      businessName: "",
      businessType: [],
      slaveRelation: [],
      cooperationModel: [],
      commercialStatus: [],
      parkEquipmentType: [],
      getByOperaList: [], // 自主停车列表
      imageUrl: "",
      payMode: [],
      isEdit: false,
      loading: false,
      effective: "1",
      startTime: "",
      defaultProps: {
        label: "label",
        children: "children",
      },
      tenantList: [],
      accessList: [
        {
          code: 1,
          desc: "平台接入",
        },
        {
          code: 2,
          desc: "三方接入",
        },
        {
          code: 4,
          desc: "同步接入",
        },
      ], // 接入方式列表
      hotData: [],
      formInline: {
        preCharge: "",
        chargeable: 0,
        parkName: "",
        areaId: "",
        parkAlias: "",
        slaveRelation: 1,
        innerPayableOld: [], // 扩展支付场景
        innerPayable: "2",
        selfParkingId: "", // 自主停车
        parentId: "",
        parkAddress: "",
        hotValue: [],
        imageUrl: "",
        amount: "",
        parkType: "",
        operationId: "",
        bootTime: new Date(),
        parkState: "",
        mapGrade: "",
        payMode: "",
        cooperationModel: "",
        commercialStatus: 1,
        parkEquipmentType: "",
        lnglat: "",
        longitude: "",
        latitude: "",
        businessType: "",
        scopeId: "",
        parkAccessType: "", // 接入方式
        innerPayTime: "", // 免费驶离时长
        berthDeploy: "", // 泊位分布
        roadLength: "", // 所在道路长度
        projectBatch: "", // 项目批次
      },
      rules: {
        preCharge: [
          {
            required: true,
            message: "请选择预付款",
            trigger: "blur",
          },
        ],
        operationId: [
          {
            required: true,
            message: "请选择商户",
            trigger: "blur",
          },
          { validator: checkOperationId, trigger: "blur" },
        ],
        amount: [
          {
            required: true,
            message: "请输入泊位数",
            trigger: "blur",
          },
          { validator: checkBerthNum, trigger: "blur" },
        ],
        commercialStatus: [
          {
            required: true,
            message: "请选择商用状态",
            trigger: "blur",
          },
        ],
        businessType: [
          {
            required: true,
            message: "请选择是否商业车场",
            trigger: "blur",
          },
        ],
        innerPayableOld: [
          {
            required: true,
            message: "请选择",
            trigger: ["blur", "change"],
          },
        ],
        payMode: [
          {
            required: true,
            message: "请选择支付方式",
            trigger: "blur",
          },
        ],
        cooperationModel: [
          {
            required: true,
            message: "请选择合作模式",
            trigger: "blur",
          },
        ],
        parentId: [
          {
            required: true,
            message: "请选择关联道路",
            trigger: "blur",
          },
        ],
        parkState: [
          {
            required: true,
            message: `请选择道路状态`,
            trigger: "blur",
          },
        ],
        slaveRelation: [
          {
            required: true,
            message: "请选择父子类型",
            trigger: "blur",
          },
        ],
        areaId: [
          {
            required: true,
            message: "请选择区域",
            trigger: "blur",
          },
        ],
        lnglat: [
          {
            required: true,
            message: "请在地图选点",
            trigger: ["blur", "change"],
          },
        ],
        parkAddress: [
          {
            required: true,
            message: "输入详细地址",
            trigger: ["blur", "change"],
          },
        ],
        bootTime: [
          {
            required: true,
            message: "请选择启用时间",
            trigger: "blur",
          },
        ],
        chargeable: [
          {
            required: true,
            message: "请选择是否支持充电",
            trigger: "blur",
          },
        ],
        parkAccessType: [
          {
            required: true,
            message: "请选择接入方式",
            trigger: "blur",
          },
        ],
        innerPayTime: [
          {
            required: false,
            trigger: "blur",
          },
          { validator: checkinnerPayTime, trigger: "blur" },
        ],
        selfParkingId: [
          {
            required: true,
            message: "请选择自主停车",
            trigger: "blur",
          },
        ],
      },
      parkImgList: [],
      berthDeployList: [
        {
          label: "单侧",
          value: 1,
        },
        {
          label: "双侧",
          value: 2,
        },
      ],
      isShowMark: false, // 是否显示自主配置提醒
      nameShow: "",
      projectBatch: [],
    };
  },
  watch: {
    "formInline.slaveRelation"(newVal) {
      this.slaveRelationDesc = newVal == 2 ? "路段" : "道路";
    },
  },
  methods: {
    goSet() {
      this.$router.push({
        path: "/selfParkingSetting",
      });
    },
    // 切换所属商户选项 查询是否有预付款功能
    changeSelectOperationId(e) {
      console.log("watchinnerPayable2", e);
      this.isShowMark = false;
      if (e) {
        this.formInline.innerPayableOld = [2];
        let resultArr = this.tenantList.filter((item) => {
          return item.operationId === e;
        });
        this.nameShow = resultArr[0].operationName;
      }
      this.$axios
        .get("/acb/2.0/preChargeSetting/getPreChargeSettingByOperationId", {
          data: { operationId: this.formInline.operationId },
        })
        .then((res) => {
          if (res.state == 0) {
            if (res.value) {
              this.showPreCharge = true;
            } else {
              this.showPreCharge = false;
            }
          }
        });
    },
    // 切换支付场景
    changeInnerPayable(e) {
      if (this.getInnerPayable(2) == 2) {
        this.formInline.innerPayableOld = [2];
      }
      // this.isShowMark = false;
      // [2,3] [3]
      if (this.getInnerPayable(3) == 3) {
        this.getByOperationId();
      } else {
        this.isShowMark = false;
      }
    },
    getInnerPayable(pams) {
      let item = this.formInline.innerPayableOld.filter((item) => item === pams);
      return item[0];
    },
    // 根据商户id查询自主停车列表
    getByOperationId(selfParkingId) {
      console.log("getByOperationId-selfParkingId", selfParkingId);
      this.$axios
        .get("/acb/2.0/selfParking/getByOperationId", {
          data: { operationId: this.formInline.operationId },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline.selfParkingId = "0";
            if (res.value.length == 0) {
              this.isShowMark = true;
            } else {
              this.getByOperaList = res.value;
              this.isShowMark = false;
              if (selfParkingId > 0) {
                this.formInline.selfParkingId = selfParkingId;
              }
            }
          }
        });
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    openTimeChange(value) {
      this.formInline.openStartTime = value[0];
      this.formInline.openStopTime = value[1];
    },
    // 关联父车场
    slaveRelationChange(parkId) {
      this.getParentDetail(parkId);
      this.getHotAreaDetails(parkId);
      if (this.formInline.slaveRelation == 2) {
        this.hotData = this.hotData.map((res) => {
          res.disabled = true;
          return res;
        });
      }
    },
    getParentDetail(parkId) {
      let url = "/acb/2.0/park/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.areaName = data.areaName;
          this.formInline.areaId = data.areaId;
          this.formInline.operationId = data.operationId;
          this.formInline.selfParkingId = data.selfParkingId;
          this.businessName = data.operationName;
          this.formInline.lnglat =
            data.longitude && data.latitude
              ? data.longitude / 1000000 + "," + data.latitude / 1000000
              : "";
          this.formInline.longitude = data.longitude;
          this.formInline.latitude = data.latitude;
          this.formInline.parkAddress = data.parkAddress;
          this.formInline.scopeId = data.scopeId;
          this.formInline.businessType = data.businessType;
          this.formInline.cooperationModel = data.cooperationModel;
          this.formInline.commercialStatus = data.commercialStatus;
          this.formInline.innerPayable = data.innerPayable;
          this.formInline.payMode = data.payMode;
          this.formInline.parkEquipmentType = data.parkEquipmentType;
          this.formInline.parkAccessType = data.parkAccessType;
          // this.formInline.thirdFeeDesc = data.thirdFeeDesc;
          this.formInline.innerPayTime = data.innerPayTime;
          this.formInline.parkState = data.parkState;
          // 自主停车和场中支付
          if (this.formInline.innerPayable == 1 && this.formInline.selfParkingId > 0) {
            this.formInline.innerPayableOld = [1, 3];
            // 自主停车
          } else if (this.formInline.innerPayable == 2 && this.formInline.selfParkingId > 0) {
            this.formInline.innerPayableOld = [3];
            // 场中支付
          } else if (this.formInline.innerPayable == 1 && this.formInline.selfParkingId == "0") {
            this.formInline.innerPayableOld = [1];
          } else {
            this.formInline.innerPayableOld = [2];
            this.formInline.selfParkingId = "";
          }
          // this.treeData = this.sortArea(res.value);
          if (this.getInnerPayable(3) == 3) {
            this.getByOperationId(data.selfParkingId);
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    beforeRemove(file, fileList) {
      if (file.parkId) {
        let url = "/acb/2.0/park/removeParkImage";
        this.$axios.get(url, { data: { id: file.parkId } }).then((res) => {
          if (res.state == 0) {
          } else {
          }
        });
      }
    },
    handleRemove(file, fileList) {
      this.fileChang = true;
      this.hideUpload = fileList.length >= this.limitCount;
      this.parkImgList = fileList;
    },
    handleImgChange(file, fileList) {
      console.log(file, fileList, "change");
      this.fileChang = true;
      this.hideUpload = fileList.length >= this.limitCount;
      this.parkImgList = fileList;

      // this.uploadData.parkId ddfffa
    },
    handleSubmit1(index) {
      const params = this.parkImgList[index];
      const fileData = new FormData();
      const baseUrl = "/acb/2.0/park/upload";
      // console.log(index);
      fileData.append("image", params.raw);
      fileData.append("parkId", this.uploadData.parkId[index]);
      const opt = {
        method: "post",
        url: baseUrl,
        data: fileData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
            ...this.uploadHeader,
          },
        },
        success: (res) => {
          if (index == 0 && this.parkImgList.length > 1) {
            // this.handleSubmit(1);
          } else {
            this.handleAvatarSuccess(res);
          }
        },
        fail: (res) => {
          this.handleAvatarSuccess(res);
        },
      };
      this.$request(opt);
    },

    handleSubmit() {
      this.parkImgList.forEach((item, index) => {
        this.uploadData.parkId.forEach((itn, itndex) => {
          if (item.parkId == itn) {
            if (index != itndex) {
              let between = this.uploadData.parkId[0];
              this.uploadData.parkId[0] = this.uploadData.parkId[1];
              this.uploadData.parkId[1] = between;
            }
          }
        });
      });

      if (this.parkImgList.length) {
        let flag = true;
        this.parkImgList.forEach((item, index) => {
          if (item.raw) {
            const fileData = new FormData();
            const baseUrl = "/acb/2.0/park/upload";
            // console.log(index);
            fileData.append("image", item.raw);
            fileData.append("parkId", this.uploadData.parkId[index]);
            const opt = {
              method: "post",
              url: baseUrl,
              data: fileData,
              config: {
                headers: {
                  "Content-Type": "multipart/form-data",
                  ...this.uploadHeader,
                },
              },
              success: (res) => {},
              fail: (res) => {
                flag = false;
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
              },
            };
            this.$request(opt);
          }
        });

        if (flag) {
          this.handleAvatarSuccess();
        }
      }
    },
    handleAvatarSuccess() {
      this.saveLoading.close();
      this.$router.push("/parkList");
    },
    areaIdDialog() {
      this.defaultProps = {
        label: "areaName",
        children: "childs",
      };
      this.key1 = "areaId";
      this.$refs.treeConpent.open();
    },
    getArea() {
      let url = "/acb/2.0/managerArea/areaAll";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getParentPark() {
      let url = "/acb/2.0/park/getParentParks";
      this.$axios
        .get(url, {
          data: {
            page: 0,
            pageSize: 0,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkParent = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    sortArea(res) {
      let arr = [];
      res.forEach((value) => {
        value.childs = [];
        if (value.parentId == 0) {
          arr.push(value);
        }
      });

      function getChild(data) {
        data.forEach((value) => {
          value.childs = res.filter((child) => {
            if (child.depth == 2) {
              child.disabled = true;
            }
            return child.parentId == value.areaId;
          });
          getChild(value.childs);
        });
      }

      getChild(arr);
      return arr;
    },
    openMap() {
      this.$refs.mapConpent.openMap(this.formInline.lnglat);
    },
    mapCancel() {
      this.$refs.mapConpent.mapClose();
    },
    treeSure(data) {
      if (data.length > 1) {
        this.$alert("只能选择一个节点！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      } else if (data.length == 0) {
        this.$alert("您未选择节点！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.key1 == "areaId") {
        this.formInline[this.key1] = data[0]["areaId"];
        this.areaName = data[0]["areaName"];
      }
      this.$refs.treeConpent.close();
    },
    treeCandle() {},
    mapSure(opt) {
      this.formInline.latitude = Math.round(opt.temLat * 1000000);
      this.formInline.longitude = Math.round(opt.temLng * 1000000);
      this.formInline.parkAddress = opt.parkAddress;
      this.formInline.lnglat = opt.hasSelect;
      this.$refs.mapConpent.mapClose();
    },
    handleTime(data) {
      this.formInline.bootTime = dateFormat(data, "yyyy-MM-dd HH");
    },
    getBusinss() {
      let url = "/acb/2.0/manager/list";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.butreeData = [res.value];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    queryTypeList() {
      let url = "/acb/2.0/park/getAddOption";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.parkType = data["parkType"];
          this.commercialStatus = data["commercialStatus"];
          this.parkEquipmentType = data["parkEquipmentType"];
          this.parkState = data["parkState"];
          this.payMode = data["payMode"];
          this.parkScope = data["parkScope"];
          this.cooperationModel = data["cooperationModel"];
          this.slaveRelation = this.$route.query.parkId
            ? data["slaveRelation"]
            : [
                { code: 1, desc: "道路" },
                { code: 2, desc: "路段" },
              ];
          this.businessType = data["businessType"];
          this.projectBatch = data["projectBatch"] || [];
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    createPark() {
      if (
        this.getInnerPayable(3) == 3 &&
        (!this.formInline.selfParkingId || this.formInline.selfParkingId == "0")
      ) {
        this.$alert("自主停车不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }

      // 没有3就是空
      if (this.getInnerPayable(3) != 3) {
        this.formInline.selfParkingId = "";
      }
      // 13 1
      if (this.getInnerPayable(1) == 1) {
        this.formInline.innerPayable = 1;
      } else {
        this.formInline.innerPayable = 2;
      }
      if (this.formInline.commercialStatus == 1) {
        delete this.formInline.bootTime;
      } else if (this.effective == 1) {
        this.formInline.bootTime = dateFormat(new Date(), "yyyy-MM-dd HH") + ":00:00";
      }
      let url = this.isEdit ? "/acb/2.0/park/modify" : "/acb/2.0/park/add";
      let method = this.isEdit ? "post" : "post";
      if (this.isEdit) {
        this.formInline.parkId = this.$route.query.parkId;
      }
      this.saveLoading = this.$loading({
        lock: true,
        text: "提交中,请稍后...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)",
      });
      let promise1 = this.$axios[method](url, {
        data: this.formInline,
      })
        .then((res) => {
          if (res.state == 0) {
            this.uploadData.parkId = this.isEdit ? this.uploadData.parkId : res.value;
            if (this.isEdit) {
              if (this.fileChang) {
                this.handleSubmit(0);
              } else {
                this.saveLoading.close();
                this.$router.push("/parkList");
              }
            } else {
              this.handleSubmit(0);
            }
            this.$axios
              .post("/acb/2.0/businessCenter/addPark", {
                data: {
                  parkId: this.uploadData.parkId,
                  businessCenterIds: this.formInline.hotValue.join(),
                },
              })
              .then((res) => {

              });
          }
        })
        .catch((err) => {
          this.saveLoading.close();
        });
    },
    async submitData() {
      if (this.formInline.parkAddress.indexOf(this.areaName) == -1) {
        this.$alert("区域与地址不一致！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }

      if (!this.parkImgList.length) {
        this.$alert("请选择上传图片", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.fileChang) {
        let isSuccess = true,
          imgMessage = "";
        for (let i = 0; i < this.parkImgList.length; i++) {
          const item = this.parkImgList[i];
          if (item.size) {
            const isLt3M = item.size / 1024 / 1024 < 3;
            if (!isLt3M) {
              isSuccess = false;
              imgMessage = "上传图片大小不能超过 3MB!";
              break;
            }
          }
        }
        if (!isSuccess) {
          this.$message.error(imgMessage);
          return;
        }
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.createPark();
        } else {
          this.isOperate = true;
          return false;
        }
      });
    },
    getParkDetail(parkId) {
      let url = "/acb/2.0/park/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          let data = res.value;
          this.formInline.innerPayableOld = [];

          for (let i in this.formInline) {
            this.formInline[i] = data[i];
          }
          // 自主停车和场中支付
          if (this.formInline.innerPayable == 1 && this.formInline.selfParkingId > 0) {
            this.formInline.innerPayableOld = [1, 3];
            // 自主停车
          } else if (this.formInline.innerPayable == 2 && this.formInline.selfParkingId > 0) {
            this.formInline.innerPayableOld = [3];
            // 场中支付
          } else if (this.formInline.innerPayable == 1 && this.formInline.selfParkingId == 0) {
            this.formInline.innerPayableOld = [1];
          } else {
            this.formInline.innerPayableOld = [2];
            this.formInline.selfParkingId = "";
          }
          this.areaName = data.areaName;
          this.businessName = data.operationName;
          this.formInline.lnglat =
            data.longitude && data.latitude
              ? data.longitude / 1000000 + "," + data.latitude / 1000000
              : "";
          if (this.formInline.preCharge) {
            this.changeSelectOperationId();
          }
          if (this.formInline.operationId) {
            this.changeSelectOperationId();
          }
          if (this.getInnerPayable(3) == 3) {
            this.getByOperationId(data.selfParkingId);
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getParkImg(parkId) {
      let url = "/acb/2.0/park/getImagebyId/" + parkId;
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          if (res.value) {
            this.parkImgList.push({ url: res.value, parkId });
          }
          this.hideUpload = this.parkImgList.length >= this.limitCount;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    getParkImgBefore(parkId) {
      this.parkImgList = [];
      let url = "/acb/2.0/park/getParkImageIds/";
      this.$axios.get(url, { data: { parkId } }).then((res) => {
        this.uploadData.parkId = res.value;
        if (res.value.length) {
          res.value.forEach((item) => {
            this.getParkImg(item);
          });
        }
      });
    },
    filterMethod(query, item) {
      return item.businessCenterName.indexOf(query) > -1;
    },
    // 热门商圈
    getHotData() {
      let url = "/acb/2.0/businessCenter/getAll";
      // console.log(this.formInline.slaveRelation)
      this.$axios.get(url).then((res) => {
        this.hotData = res.value;
      });
    },
    handleSlaveChnage() {
      this.formInline.amount = "";
      if (this.formInline.slaveRelation == 2) {
        this.hotData = this.hotData.map((res) => {
          res.disabled = true;
          return res;
        });
      } else {
        this.hotData = this.hotData.map((res) => {
          res.disabled = false;
          return res;
        });
      }
    },
    getHotAreaDetails(id) {
      let url = "/acb/2.0/businessCenter/getByParkId";
      this.$axios
        .get(url, {
          data: {
            parkId: id,
          },
        })
        .then((res) => {
          if (this.formInline.slaveRelation == 2) {
            this.hotData = this.hotData.map((res) => {
              res.disabled = true;
              return res;
            });
          }
          this.formInline.hotValue = res.value.map((res) => {
            return res.businessCenterId;
          });
        });
    },
  },
  components: {
    mapCon,
    treeCon,
  },
  created() {
    this.getTenantList();
    this.queryTypeList();
    this.getParentPark();
    this.getHotData();
    if (this.$route.query.parkId) {
      this.isEdit = true;
      this.getParkImgBefore(this.$route.query.parkId);
      this.getParkDetail(this.$route.query.parkId);
      setTimeout(() => {
        this.getHotAreaDetails(this.$route.query.parkId);
      }, 150);
      this.effective = "2";
    }
  },
  mounted() {},
  computed: {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.form-footer {
  padding: 12px 40px;
  background: #fff;
  box-sizing: border-box;
  position: sticky;
  bottom: -20px;
  z-index: 1;
  .el-button {
    padding: 11px 20px;
  }
}
/deep/.content {
  background: #fff;
  overflow: hidden;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 16px;
  &:not(&:first-child) {
    cursor: pointer;
  }
  .el-textarea__inner, .el-input__inner{
    width: 300px;
    height: 36px;
  }
  .el-input--small .el-input__inner {
    width: 149px;
    height: 28px;
    border-radius: 2px;
  }
  .custom-input.el-input {
    width: 272px;
    .el-input__inner {
      width: 264px;
    }
  }
}
.map1 {
  margin-left: 8px;
  padding: 11px 15px;
}
.uploadPic {
  width: 100%;
  margin-left: 42px;
  display: flex;
  .park-title {
    font-size: 14px;
    color: #4e5969;
    margin: 10px 12px 0 0;
    &:before {
      content: '*';
      color: #F56C6C;
      margin-right: 4px;
    }
  }
  /deep/.upload-img {
    .el-upload {
      width: 247px;
      height: 185px;
      border-radius: 2px;
      background-color: #f2f3f5;
      border: 1px dashed #E5E6EB;
      .park-icon-img {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .park-icon-title {
          color: #1D2129;
          line-height: 1;
        }
        .park-icon-upload {
          width: 43px;
          height: 43px;
          color: #4E5969;
          font-size: 25px;
          line-height: 1;
          margin-top: 20px;
        }
        .park-icon-box {
          line-height: 20px;
          color: #86909c;
          font-size: 12px;
          padding: 16px 16px 0;
        }
      }
    }
    .el-upload-list--picture-card .el-upload-list__item {
      width: 247px;
      height: 185px;
      border-radius: 2px;
      margin-right: 16px;
      .el-upload-list__item-delete {
        .el-icon-delete:before {
          font-size: 22px;
        }
      }
    }
  }
}
.mt20 {
  margin-top: 20px;
}
.title-box {
  width: 100%;
  display: flex;
  i {
    margin-right: 6px;
  }
  span {
    font-size: 15px;
    color: #2F2F51;
  }
}
.flex-box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
/deep/.lng-box {
  position: relative;
  .el-input, .el-input__inner {
    width: 206px;
  }
}
.third-box {
  margin-bottom: 0;
  width: 32%;
}

.relevant-box {
  .el-form-item {
    width: 723px;
  }
}

.length-box .el-input {
  width: 51%;
}

.tips-box {
  position: absolute;
  color: #AAAAAA;
  font-size: 13px;
  width: 210%;
  top: 30px;
}
/deep/ .textarea-input {
  textarea::-webkit-input-placeholder {
    font-family: 'PingFang';
  }
}
/deep/ {
  .hide .el-upload--picture-card {
    display: none;
  }
}
.isMark {
  width: 300px;
  color: red;
  line-height: 20px;
  margin-bottom: -25px;
  z-index:99;
}
.dialog_button {
  width: 300px;
  height: 36px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #f2f3f5;
  &.el-button {
    padding: 11px 20px;
  }
}
</style>
